<template>
    <div v-if="isLoggedIn" class="container page">

        <!--<div>
            <sitecode-image src="topRecognition.jpg" class="img-fluid w-100" />
        </div>-->

        <section v-if="displayPart==0" class="card card-default">
            <div class="card-header">
                <h5>Send To All</h5>
                
            </div>
            <div class="card-body">
                <div><strong>Send to all stores:</strong></div>
                <b-form-radio-group id="radiogroupAllShops" v-model="fReward.form.isAllShops" name="allShops">
                    <div class="row">
                        <div class="col-sm-2 col-lg-1">
                            <b-form-radio :value="false">No</b-form-radio>
                        </div>
                        <div class="col">
                            <b-form-radio :value="true">Yes <i><small>(This will send to everyone across all stores)</small></i></b-form-radio>
                        </div>
                    </div>
                </b-form-radio-group>
                <template v-if="!fReward.form.isAllShops">
                    <hr />
                    <div class="row mt-3">
                        <div class="col-sm-6"><strong>Selected Recipients:</strong></div>
                        <div class="col-sm-6 text-right"></div>
                    </div>
                    <div v-for="r in selectedMems" :key="r._rowNum" class="row">
                        <template v-if="r.isEntireDivision">
                            <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                            <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                        </template>
                        <template v-else>
                            <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                            <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                            <div class="col">{{ r.division }}</div>
                        </template>
                    </div>

                    <div v-if="selectedMems.length===0" class="text-danger">Please select a recipient.</div>
                    <div class="mt-1">
                        <b-button type="button" pill @click="$bvModal.show('modalChooseNominees')" variant="primary" size="sm" class="mt-2">{{selectedMems.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                        <!--<a href="#" @click.prevent="$bvModal.show('modalChooseNominees')">Select Nominees</a>-->
                    </div>
                </template>
                <hr />
                <b-form @submit.prevent="onSubmitReward" novalidate>

                    <b-form-group id="groupSendBy" label-for="sendBy" description="" :invalid-feedback="fReward.errors.sendBy" :state="fReward.state.sendBy" class="mt-4">
                        <b-form-radio-group id="radiogroupSendBy" v-model="fReward.form.sendBy" name="sendBy" :state="fReward.state.sendBy">
                            <div class="row">
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="email">Email</b-form-radio>
                                </div>
                                <div class="col-sm-3 col-lg-2">
                                    <b-form-radio value="sms">SMS</b-form-radio>
                                </div>
                            </div>
                        </b-form-radio-group>
                    </b-form-group>
                    <div class="alert alert-primary" role="alert" v-show="fReward.form.sendBy=='sms'">
                        <content-db contentKey="topmsgsendtoall" :isCache="true"></content-db>
                    </div>
                    <hr />
                    <b-form-group id="groupRewardMessage" label-for="txtRewardMessage" label="Message to Recipients" :invalid-feedback="fReward.errors.message" :description="(isSendBySMS?150:350)-fReward.form.message.length + ' characters remaining'">
                        <b-form-textarea id="txtRewardMessage" :disabled="fReward.form.sendBy===null" v-model="fReward.form.message" :state="fReward.state.message" :placeholder="isSendBySMS ? 'Short SMS message to recipients up to 150 characters' : 'Short email message to recipients up to 350 characters'" rows="3" max-rows="5" :maxlength="(isSendBySMS?150:350)" />
                    </b-form-group>

                    <hr />

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>

        <section v-if="displayPart==1" class="card card-default">
            <div class="card-header">
                <h5>Send To All</h5>
            </div>
            <div class="card-body">
                <b-alert variant="success" show>
                    <h4 class="alert-heading">Send To All</h4>
                    <p>
                        Successfully submitted Send To All.
                    </p>
                </b-alert>
            </div>
            <div class="card-footer">
                <div class="text-right"><button type="button" @click="displayPart=0" class="btn btn-primary">Send Another</button></div>
            </div>

        </section>



        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Nominees -->
        <b-modal id="modalChooseNominees" title="Send To All" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Select recipients"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMems"
                           :isAllowWholeDivision="true"
                           :isSingle="false"
                           :showContinue="false"
                           class="mb-3" />

            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseNominees')">Done</b-button></div>
        </b-modal>

    </div>

</template>

<script>
    // @ is an alias to /src
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
	import ContentDb from '@/components/ContentDb';


    export default {
        name: 'Reward',
        components: {
            MemberSelect,
			ContentDb
            //'sitecode-image': SitecodeImage
        },
        data() {
            return {
                displayPart: 0,
                fReward: {
					form: { isAllShops: false, message: '', sendBy: null, recipients: [] }
                },

                selectedMems: [],

                roles: { ca: false, cc: false, idea: false, inv: false },

                reportTitle: '',

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            countryList() {
                if (this.isCountryFixed) { //only show the default cluster.
                    return this.$store.state.countryList.filter(country => country.countryCode === this.defaultCountryCode);
                } else {
                    return [{ countryCode: null, country: '-- Please select a Cluster--', disabled: true }]
                        .concat(this.$store.state.countryList);
                }
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            },
            isSendBySMS() {
                return this.fReward.form.sendBy === 'sms';
			}
        },
        created() {
            //formSetState must be called on creation, to ensure all fields are setup
            this.formSetState(this.fReward, true, true) //formObj, resetState, resetForm

            this.$store
                .dispatch('getDivisionList', {})
                .then(() => {
                    this.displayPart = 0;
                })
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error)));
                });


        },
        mounted() {

        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },

            onSubmitReward() {
                this.formSetState(this.fReward, true);//formObj, resetState, resetForm
                if (this.allShops) {
                    this.fReward.form.recipients = ['all_shops:']
                } else {
					if (this.selectedMems.length === 0) {
						this.msgBox('Please select at least one recipient.', 'Recipients required');
						return;
					} 
                    this.fReward.form.recipients = this.selectedMems.map(item => item.isEntireDivision ? 'div:' + item.entireDivisionCode : item.memberId);
                }

                //this.displayPart = 1;
                if (this.displayPart == 0) {
                    return axios
                        .post(
                            '/api/reward/sendtoall',
                            this.fReward.form
                        )
                        .then(({ data }) => {
                            console.log(data);
                            this.displayPart = 1;
							this.msgBox('Successfully sent ' + data.sentCount + ' messages.');
							this.selectedMems = [];
                            this.formSetState(this.fReward, true, true);
							//this.$store.dispatch('memberStore/getMemberData');
                        })
                        .catch((error) => {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fReward.errors = error.response.data.formErrors;
                                this.formSetState(this.fReward);
                            }
                        })
                        ;
                }
            },

        }

    }
</script>
