<template v-if="isLoggedIn">

    <div class="homePg">
        <section class="container homePg mt-5">
            <div class="mb-3"><!-- <sdj:AdRotator RotatorType="TopAd" runat="server" HideSlideDots="true" /> --></div>
            <div class="row mb-5">
                <div class="col-md-9 pt-5">
                    <img src="../assets/image/thanksForSupport.png" class="img-fluid" />
                    <p class="mt-3">We're so pleased you've chosen to partner with us, and we're just as thrilled that we can share these amazing rewards with you.</p>

                    <p class="pr-3">
                        Browse through our retail partners below
                        and <strong>redeem your Bucks</strong> for a voucher of your choice. What are you waiting for?
                    </p>
                    <p><strong>Spoil yourself!</strong></p>

                </div>
                <div class="col-md-3 text-center text-md-left">
                    <router-link to="/rewards" class="hvrlinkbutton"><img src="../assets/image/Redeem.png" class="img-fluid" /></router-link>
                </div>
            </div>
            <div>

            </div>
            <div class="mt-5 mb-5 pt-5"><router-link to="/redeem" class="hvrlinkbutton"><img src="../assets/image/LogosRow.png" class="img-fluid" /></router-link></div>


        </section>
        <section v-if="isLoggedIn" class="container-fluid">
            <div class=""><img src="../assets/image/homePic.jpg" class="w-100" /></div>
        </section>
    </div>
</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'Home',
		components: {}, //SitecodeImage 
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
			memberClusterCode() {
				return this.$store.state.memberStore.member.clusterCode
			},
			memberDivisionCode() {
				return this.$store.state.memberStore.member.divisionCode
			},
            isManager() {
                return this.$store.state.memberStore.member.isManager
            },
            recentRecognition() {
                return this.$store.state.memberStore.homeRecentRecognition
            },
            getToKnow() {
                return this.$store.state.memberStore.homeGetToKnow
            },
            messagesSent() {
                return this.$store.state.memberStore.homeStats.messagesSent
            },
            rewardsIssued() {
                return this.$store.state.memberStore.homeStats.rewardsIssued
            },
            rewardsRedeemed() {
                return this.$store.state.memberStore.homeStats.rewardsRedeemed
            },
            windowWidth() {
                return this.$store.state.windowWidth
            }

        },
        mounted() {
            this.$nextTick(() => {
                //console.log('home.vue next tick mounted');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        updated() {
            this.$nextTick(() => {
                //console.log('home.vue next tick updated');
                this.handleResize();
                //setTimeout(this.handleResize, 5000);
            });
        },
        watch: {
			windowWidth() { //newWidth, oldWidth
                //console.log(`width changed to ${newWidth} from ${oldWidth}`);
                this.handleResize();
            }
        },
        methods: {
            handleResize: function () {
                if (!this.$refs.homeicon1 || !this.$refs.homeicon1.firstElementChild) {
                    //console.log('handleResize: cannot find homeicon1');
                    setTimeout(this.handleResize, 250)
                    return;
                }

                let h = this.$refs.homeicon1.firstElementChild.offsetHeight; //$('.homeicon1').innerHeight();
                let w = this.$refs.homeicon1.firstElementChild.offsetWidth; //$('.homeicon1').innerWidth();
                if (w < 50 || h < 50) {
					//console.log('handleResize: homeicon1 w or h = 0');
					setTimeout(this.handleResize, 250)
					return;
                }
				//console.log('handleResize: homeicon1 w: ' + w + ', h: ' + h);

                this.$refs.homeShoutout.style.height = (h * 2 + 24) + "px";
                this.$refs.homeShoutout.style.width = w + "px";
                this.$refs.homeGetToKnow.style.height = h + "px";
                this.$refs.homeGetToKnow.style.width = w + "px";

                let titleHeight = this.$refs.homeRightTitle.offsetHeight;
                document.getElementsByClassName('homeGtkSlide').forEach(
                    item => {
                        item.style.height = (h - titleHeight) + "px"
                    }
                );

            }
        }
    }
</script>
