<template>
    <section class="container page">
        <div v-if="pgMode=='step1'" class="card card-default">
            <b-form @submit.prevent="onStep1" novalidate>
                <div class="card-header">
                    <h4>Forgot Password</h4>
                </div>
                <div class="card-body">

                    <div class="mb-3">
                        <p>Enter the mobile number associated with your account</p>
                        <p>We will sent you an SMS with a one time pin (OTP) to your mobile phone.</p>
                    </div>
                    <b-form-group class="my-4 ml-3" id="cellNumGrp" label-for="cellNumTxt" label="Mobile Number:" :invalid-feedback="fProfile.errors.cellNum" description="We will send you a one time pin (OTP) to verify your mobile number.">
                        <b-form-input id="cellNumTxt" v-model="fProfile.form.cellNum" :state="fProfile.state.cellNum" autocomplete="username" placeholder="" maxlength="20" class="col-sm-5" />
                    </b-form-group>
                    
                    <a href="#" @click.prevent="$bvModal.show('modalTerms')">Terms and Conditions</a>
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>

        <div v-if="pgMode=='step2'" class="card card-default">
            <b-form @submit.prevent="onStep2" novalidate>
                <div class="card-header">
                    <h4>Forgot Password - Step 2</h4>
                </div>
                <div class="card-body">
                    <div class="alert alert-info" role="alert">
                        We have sent a one time pin to: <b>{{ fProfile.form.cellNum }}</b>.
                    </div>
                    
                    <b-form-group id="otpGrp" label-for="otpTxt" label="One Time Pin (OTP):" :invalid-feedback="fProfile.errors.otp" description="Enter the OTP you recieve by SMS.">
                        <b-form-input id="otpTxt" v-model="fProfile.form.otp" :state="fProfile.state.otp" placeholder="" maxlength="20" autocomplete="one-time-code" />
                    </b-form-group>


                    <b-form-group id="passwordGrp" label-for="passwordTxt" label="New password:" description="Enter a new password of at least 8 characters that includes at least 1 letter and 1 number." :invalid-feedback="fProfile.errors.password">
                        <b-form-input type="password" id="passwordTxt" v-model="fProfile.form.password" :state="fProfile.state.password" autocomplete="new-password" placeholder="" maxlength="80" />
                    </b-form-group>
                </div>
                <div class="card-footer">
                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </div>
            </b-form>
        </div>


        <div v-if="pgMode=='done'" class="card card-default">
            <div class="card-header">
                Password Update Complete
            </div>
            <div class="card-body">
                <p>You have successfully updated your password.</p>
                <p><router-link to="/hello" class="hvrlinkbutton mx-2">Click here to login</router-link></p>
            </div>
        </div>


        <!-- Modal  -->
        <b-modal id="modalTerms" scrollable ok-only size="lg" title="Terms and Conditions">
            <content-db contentKey="terms" :isCache="true"></content-db>
        </b-modal>

    </section>
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import ContentDb from '@/components/ContentDb';

    export default {
        name: 'ForgotPassword',
        components: { ContentDb },
        data() {
            return {
                pgMode: 'step1',
                fProfile: {
					form: { cellNum: '', password: '', otp: '', auth: '' }
                },
                termsAccepted: false,

            }
        },
        computed: {

        },
        props: {
        },
        watch: {
            //'$route': 'doNavigation',
        },
        created() {
            this.formSetState(this.fProfile, true, true); //formObj, resetState, resetForm
        },
        mounted() {

        },
        methods: {
            //*** formStatusUpdate - used to display form errors.
            //Ensure this.form contains the list of fields.
            //Call  formStatusUpdate(true) to initialise/clear the form errors.
            //formObject is the object that contains the following objects:  .form, .formState, .formErrors
            formSetState(formObj, resetState, resetForm) {
                if (typeof resetState === 'undefined') resetState = false;
                if (typeof resetForm === 'undefined') resetForm = false;
                if (typeof formObj.init === 'undefined') formObj.init = { ...formObj.form }; //Initialise .formInit object to be a copy of .form object.
                if (typeof formObj.errors === 'undefined') this.$set(formObj, 'errors', {}); //use this.$set  to make errors object reactive
                if (typeof formObj.state === 'undefined') this.$set(formObj, 'state', {});   //use this.$set  to make state object reactive

                if (resetState || resetForm) {
                    if (resetForm) formObj.form = { ...formObj.init }; //copy .init to .form

                    formObj.errors = {}; //reset formErrors object
                    formObj.state = {};
                }

                //update formState
                var fields = Object.getOwnPropertyNames(formObj.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (resetState) {
                        formObj.state[field] = null;
                    } else if (typeof formObj.errors[field] === 'undefined' || formObj.errors[field] === '') {
                        formObj.state[field] = true;
                    } else {
                        formObj.state[field] = false;
                    }
                }
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },
            //doNavigation() {
            //    const param = this.urlParam;//this.$route.params.urlParam;//  this.urlParam;

            //    if (param === 'update') {
            //        //console.log('doNavigationBasket' + param);
            //        this.pgMode = 'edit'
            //    } else {
            //        this.pgMode = 'view'
            //    }
            //},

			onStep1() {
				this.fProfile.form.otp = '';
				this.fProfile.form.auth = '';
				this.formSetState(this.fProfile, true);//formObj, resetState, resetForm

				return axios
					.post(
						'/api/member/passwordsendcell2factorsms',
                        { 'cellNum' : this.fProfile.form.cellNum }
					)
					.then(({ data }) => {
						this.fProfile.form.auth = data.auth;
						this.pgMode = 'step2';
					})
					.catch((error) => {
						if (error && error.response) {
							this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
							if (error.response.data.formErrors) {
								this.fProfile.errors = error.response.data.formErrors;
								this.formSetState(this.fProfile);
							}
						} else {
							this.msgBox('Unexpected Error');
						}
					});
			},


            onStep2() {
				//if (!this.termsAccepted) {
				//	this.msgBox('Terms and Conditions are required to be accepted to participate in this program');
				//	return;
				//}
                return axios
                    .post(
                        '/api/member/passwordcell2factorverify',
                        this.fProfile.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.pgMode = 'done';
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                            if (error.response.data.formErrors) {
                                this.fProfile.errors = error.response.data.formErrors;
                                this.formSetState(this.fProfile);
                            }
                        } else {
                            this.msgBox('Unexpected Error');
                        }
                    });
            },

        }

    }
</script>
