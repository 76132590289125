<template>
    <footer v-if="!isLoginPage" class="pagefooter clearfix d-print-none mt-5 py-3">
        <div class="container">
            <div class="row">
                <div class="col-sm-10 text-center pt-2">
                    <span class="mx-2">&copy; {{ new Date().getFullYear() }} Bayport</span>
                    <template v-if="isLoggedIn"> | <router-link to="/howitworks" class="hvrlinkbutton mx-2">How&nbsp;It&nbsp;Works</router-link></template>
                    <!--| <router-link to="/about" class="hvrlinkbutton mx-2">About&nbsp;Us</router-link>
                    | <router-link to="/terms" class="hvrlinkbutton mx-2">Terms&nbsp;and&nbsp;Conditions</router-link>-->
                    | <router-link to="/disclaimer" class="hvrlinkbutton mx-2">Disclaimer</router-link>
                    | <a href="#" @click.prevent="$bvModal.show('modalCookiePolicyPreferences')">Cookie Preferences</a>
                    | <router-link to="/privacy" class="hvrlinkbutton mx-2">Privacy Policy</router-link>
                    <template v-if="isLoggedIn">| <router-link to="/newsletters" class="hvrlinkbutton mx-2">Newsletters</router-link></template>
                    <template v-if="isLoggedIn"> | <router-link to="/contactus" class="hvrlinkbutton mx-2">Contact Us</router-link></template>
                    
                </div>
                <div class="col-sm-2 text-right">
                    <a href="https://www.therewardsfactory.co.za/" target="_blank"><img src="../assets/image/loginPoweredBy.png" class="img-fluid ml-2" /></a>
                </div>
            </div>
        </div>

        <!--<img src="../assets/image/footer.png" class="img-fluid d-none d-lg-block" />
    <img src="../assets/image/footerMobi.png" class="img-fluid d-lg-none" />-->
    </footer>
</template>

<script>
//import { mapState } from 'vuex'
    export default {
        name: 'AppFooter',
        components: {
        },
        computed: {
			isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            rootFolder() {
                return process.env.BASE_URL
            },
            isLoginPage() {
                return this.$route.name === "Hello";
			}
        }
    }
</script>

