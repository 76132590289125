<template>
    <div v-if="isLoggedIn" class="container page">


        <template v-if="displayPart==1">
            <div class="text-right"><a href="#" v-on:click.prevent="formReset">Reset</a></div>
          
        </template>



        <section v-if="displayPart==0" class="card card-default">
            <div class="card-header">
                <h5>Nominate</h5>
            </div>
            <div class="card-body">
                <div><strong>Selected Employees to Nominate:</strong></div>
                <div v-for="r in selectedMembers" :key="r._rowNum" class="row">
                    <template v-if="r.isEntireDivision">
                        <div class="col-1 text-right"><b-icon icon="collection" style="color:#9f34a0"></b-icon></div>
                        <div class="col" style="color:#9f34a0">Entire division: {{ r.division }}</div>
                    </template>
                    <template v-else>
                        <div class="col-1 text-right"><b-icon icon="person" style="color:#ccc"></b-icon></div>
                        <div class="col">{{ r.firstname + ' ' + r.surname }}</div>
                        <div class="col">{{ r.division }}</div>
                    </template>
                </div>
                <div class="mt-1">
                    <b-button type="button" pill @click="$bvModal.show('modalChooseMembers')" variant="primary" size="sm" class="mt-2">{{selectedMembers.length===0 ? 'Select' : 'Change'}}  Recipient</b-button>
                    <!--<a href="#" @click.prevent="$bvModal.show('modalChooseMembers')">Select Members</a>-->
                </div>


                <hr />
                <b-form @submit.prevent="onSubmitNom" novalidate>

                    <b-form-group id="groupAwardCategory" label-for="selectNomCat" label="Company Value:" :invalid-feedback="formErrors.nomCat" description="">
                        <b-form-select v-model="form.nomCat" :options="nomCatList" value-field="nomCatValue" text-field="nomCat" :state="formState.nomCat" />
                    </b-form-group>

                    <!--<b-form-group id="groupEmail" label-for="txtEmail" label="Email Address:" :invalid-feedback="formErrors.EmailAddress" description="Your email address">
                    <b-form-input id="txtEmail" v-model="form.EmailAddress" type="text" :state="formState.EmailAddress" placeholder="Enter your email address" />
                </b-form-group>-->

                    <b-form-group id="groupReason" label-for="txtMessage" label="Reason:" :invalid-feedback="formErrors.Reason" :description="1500-form.reason.length + ' characters remaining'">
                        <b-form-textarea id="txtReason" v-model="form.reason" :state="formState.reason" placeholder="Provide a short reason" rows="3" max-rows="5" maxlength="1500" />
                    </b-form-group>

                    <div class="text-right"><b-button type="submit" variant="primary">Submit</b-button></div>
                </b-form>
            </div>
        </section>


        <!--
    **************************************************************************************************************************************************
                                                                M O D A L S
    **************************************************************************************************************************************************
    -->
        <!-- Modal - Members -->
        <b-modal id="modalChooseMembers" title="Select Members" hide-footer size="xl" no-close-on-backdrop>
            <member-select title="Choose who to nominate"
                           selectedTitle="Recipients"
                           :defaultCountryCode="memberClusterCode"
                           :defaultDivisionCode="memberDivisionCode"
                           :selectedMems="selectedMembers"
                           :isAllowWholeDivision="false"
                           :isSingle="false"
                           :showContinue="false"
                           class="mb-3" />
           
            <hr />
            <div class="text-right"><b-button type="submit" variant="primary" @click="$bvModal.hide('modalChooseMembers')">Done</b-button></div>
        </b-modal>


    </div>



</template>

<script>
    // @ is an alias to /src
    //import HelloWorld from '@/components/HelloWorld.vue'
    //import SitecodeImage from '@/components/SitecodeImage';
    import axios from 'axios'
    //import sjData from '@/sjUtilData'
    import MemberSelect from '@/components/MemberSelect';
    // import SitecodeImage from '@/components/SitecodeImage';


    export default {
        name: 'Nominate',
        components: { MemberSelect },
        data() {
            return {
                displayPart: 0,
                formInit: {},
                form: { nomCat: null, reason: '', members:null },
                formErrors: {},
                formState: {},


                selectedMembers: [],
                nomCatList: []

            }
        },
        computed: {
            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },
            memberClusterCode() {
                return this.$store.state.memberStore.member.clusterCode
            },
            memberDivisionCode() {
                return this.$store.state.memberStore.member.divisionCode
            }
        },
        created() {
            this.formInit = { ...this.form }; //Create a copy of the form init values, so we can use this to reset the form
			//Integrity, Teamwork, Diversity, Innovation, Accountability, Excellence
			this.nomCatList = [
				{ nomCatValue: null, nomCat: '-- Please select a Value --', disabled: true },
				{ nomCatValue: 'Integrity', nomCat: 'Integrity' },
				{ nomCatValue: 'Teamwork', nomCat: 'Teamwork' },
				{ nomCatValue: 'Diversity', nomCat: 'Diversity' },
				{ nomCatValue: 'Innovation', nomCat: 'Innovation' },
				{ nomCatValue: 'Accountability', nomCat: 'Accountability' },
				{ nomCatValue: 'Excellence', nomCat: 'Excellence' },
			]

            this.$store
                .dispatch('getDivisionList', {})
                //.then(() => {
                //})
                .catch((error) => {
                    this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                })
        },

        mounted() {
            this.formStateUpdate(true);

        },
        watch: {
            memberClusterCode(val) {
                if (this.nomCatList.length === 0 && val !== null) this.getNomCats();
            },
        },
        methods: {

            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            },

            formReset() {
                this.form = { ...this.formInit };
                this.displayPart = 0;
                this.selectedMembers = [];
            },

            formStateUpdate(init) {

                var fields = Object.getOwnPropertyNames(this.form);
                for (let i = 0; i < fields.length; i++) {
                    let field = fields[i];
                    if (init) {
                        this.formState[field] = null;
                        this.formErrors = {}; //reset formErrors object
                    } else if (typeof this.formErrors[field] === 'undefined' || this.formErrors[field] === '') {
                        this.formState[field] = true;
                        console.log("field: " + field + " " + JSON.stringify(this.formState[field]));
                    } else {
                        this.formState[field] = false;
                        console.log("field: " + field + " false");
                    }
                }
            },

            onSubmitNom() {
                this.form.members = this.selectedMembers.map(nominee => nominee.isEntireDivision ? 'div:' + nominee.entireDivisionCode : nominee.memberId)

                this.formStateUpdate(true);
                return axios
                    .post(
                        '/api/nom/newnom',
                        this.form
                    )
                    .then(({ data }) => {
                        console.log(data);
                        this.formReset();
                        this.msgBox('Successfully submitted nomination');
                    })
                    .catch((error) => {
                        this.msgBox(error.response.data.message ? error.response.data.message : ('An error has occurred: ' + JSON.stringify(error.response.data)));
                        if (error.response.data.formErrors) {
                            this.formErrors = error.response.data.formErrors;
                            this.formStateUpdate(false);
                        }
                    })
                    ;
            }
        }


    }
</script>
