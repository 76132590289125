<template>
    <img v-if="isLoggedIn" :src="imgSrc" />
</template>

<script>
    export default {
		name: 'sitecode-image',
        props: {
            src: String
        },
        computed: {
            isLoggedIn() {
				return this.$store.state.isLoggedIn
			},
            imgSrc() {
				return require('../assets/image/' + this.src);
				//return require('../assets/image/' + this.$store.state.siteTemplate + '/' + this.src);
            }
        }
    }
</script>

