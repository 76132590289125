import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import Loading from '../views/Loading.vue'
import Logout from '../views/Logout.vue'
import Hello from '../views/Hello.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import LoginSuccess from '../components/LoginSuccess.vue'

import ContactUs from '../views/ContactUs.vue'
import MyProfile from '../views/MyProfile.vue'
import Nominate from '../views/Nominate.vue'
import AdhocRecognition from '../views/AdhocRecognition.vue'

import Rewards from '../views/Rewards.vue'
import Reward from '../views/Reward.vue'
import RewardPayItForward from '../views/RewardPayItForward.vue'
import Recognise from '../views/Recognise.vue'
import SendToAll from '../views/SendToAll.vue'

//import GetToKnow from '../views/GetToKnow.vue'
import RecentRecognition from '../views/RecentRecognition.vue'

import ReportManagement from '../views/ReportManagement.vue'
import ManagementStatement from '../views/ManagementStatement.vue'
import Statement from '../views/Statement.vue'
import Orders from '../views/Orders.vue'

import ContentPage from '../views/ContentPage.vue'

import PayItForwardStatement from '../views/PayItForwardStatement.vue'
import CommissionStatement from '../views/CommissionStatement.vue'
import ReportBalances from '../views/ReportBalances.vue'


//import store from '../store/store'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/loginsuccess', name: 'LoginSuccess', component: LoginSuccess, meta: { allowAnonymous: true } },
    //{ path: '/ssoaccess/authenticating', name: 'ssoAuthProcessing', component: AuthSSO, meta: { allowAnonymous: true } },
    //{ path: '/ssoaccess/:siteCode/:ssoKey', name: 'ssoAuth', component: AuthSSO, meta: { allowAnonymous: true } },
    { path: '/logout', name: 'Logout', component: Logout, meta: { allowAnonymous: true } },
    { path: '/hello', name: 'Hello', component: Hello, meta: { allowAnonymous: true } },
    { path: '/register', name: 'Register', component: Register, meta: { allowAnonymous: true } },
    { path: '/forgotpassword', name: 'ForgotPassword', component: ForgotPassword, meta: { allowAnonymous: true } },
    //{ path: '/loading', name: 'Loading', component: Loading, meta: { allowAnonymous: true } }

    { path: '/contactus', name: 'ContactUs', component: ContactUs, meta: { allowAnonymous: true } },
    { path: '/myprofile', name: 'MyProfile', component: MyProfile, meta: { allowAnonymous: false } },
    { path: '/myprofile/:urlParam', name: 'MyProfileUpdate', component: MyProfile, props: true, meta: { allowAnonymous: false } },

    //{
    //    path: '/about', name: 'About',
    //    // route level code-splitting
    //    // this generates a separate chunk (about.[hash].js) for this route
    //    // which is lazy-loaded when the route is visited.
    //    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    //},

    { path: '/nominate', name: 'Nominate', component: Nominate, meta: { allowAnonymous: false } },
    { path: '/adhocrecognition', name: 'AdhocRecognition', component: AdhocRecognition, meta: { allowAnonymous: false } },

    { path: '/reward', name: 'Reward', component: Reward, meta: { allowAnonymous: false } },
    { path: '/reward/:selectedMemberId', name: 'Reward2', component: Reward, meta: { allowAnonymous: false } },

    { path: '/rewards/:urlParam', component: Rewards, props: true, meta: { allowAnonymous: false } },
    { path: '/rewards', component: Rewards, meta: { allowAnonymous: false } },

    { path: '/orders', component: Orders, meta: { allowAnonymous: false } },
    //{ path: '/rewards', redirect: '/rewards/' },

    { path: '/rewardpayitforward', name: 'RewardPayItForward', component: RewardPayItForward, meta: { allowAnonymous: false } },
    { path: '/sendtoall', name: 'SendToAll', component: SendToAll, meta: { allowAnonymous: false } },
    
    { path: '/recognise', name: 'Recognise', component: Recognise, meta: { allowAnonymous: false } },
    { path: '/recognise/:selectedMemberId', name: 'Recognise2', component: Recognise, meta: { allowAnonymous: false } },

    { path: '/gettoknow', name: 'GetToKnow', component: () => import('../views/GetToKnow.vue'), meta: { allowAnonymous: false } },
    { path: '/gettoknow/:selectedMemberId', name: 'GetToKnow2', component: () => import('../views/GetToKnow.vue'), meta: { allowAnonymous: false } },

    { path: '/recentrecognition', name: 'RecentRecognition', component: RecentRecognition, meta: { allowAnonymous: false } },

    { path: '/reportmanagement', name: 'ReportManagement', component: ReportManagement, meta: { allowAnonymous: false } },
    { path: '/managementstatement', name: 'ManagementStatement', component: ManagementStatement, meta: { allowAnonymous: false } },
    { path: '/statement', name: 'Statement', component: Statement, meta: { allowAnonymous: false } },

    { path: '/payitforwardstatement', name: 'PayItForwardStatement', component: PayItForwardStatement, meta: { allowAnonymous: false } },
    { path: '/reportbalances', name: 'ReportBalances', component: ReportBalances, meta: { allowAnonymous: false } },
    { path: '/commissionstatement', name: 'CommissionStatement', component: CommissionStatement, meta: { allowAnonymous: false } },

    { path: '/:contentKey', name: 'ContentPage', component: ContentPage, props: true, meta: { allowAnonymous: true } },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//Router Guards
router.beforeEach((to, from, next) => {
    let isAllowAnonymous = to.matched.some(m => m.meta.allowAnonymous);
    if (isAllowAnonymous) {
        next();
        return;
    }

    //let isLoggedIn = !store.state.isLoggedIn;
    let bearerToken = sessionStorage.getItem('SecBr');
    if (!bearerToken) {
        //console.log('routerguard: request navigation to: ' + to.name + ', not logged in - redirect to Login');
        next({ name: 'Hello' }); //Redirect to login
    } else {
        next()
    }
    // if (to.name !== 'Login') 

})

export default router
